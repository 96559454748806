//  Core

.scroll-area {
  overflow-x: hidden;
  height: 300px;
}

.scroll-area-xs {
  height: 150px;
  overflow-x: hidden;
}

.scroll-area-sm {
  height: 215px;
  overflow-x: hidden;
}

.scroll-area-lg {
  height: 420px;
  overflow-x: hidden;
}

.scroll-area-xl {
  height: 600px;
  overflow-x: hidden;
}

.scroll-area-x {
  overflow-x: auto;
  width: 100%;
  max-width: 100%;
}

.shadow-overflow {
  position: relative;

  &::after,
  &::before {
    width: 100%;
    bottom: auto;
    top: 0;
    left: 0;
    height: $spacer;
    position: absolute;
    z-index: 4;
    content: '';
    background: linear-gradient(to bottom, rgba($white, 1) 20%, rgba($white, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$white', endColorstr='$white', GradientType=0);
  }

  &::after {
    bottom: 0;
    top: auto;

    background: linear-gradient(to bottom, rgba($white, 0) 0%, rgba($white, 1) 80%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$white', endColorstr='$white', GradientType=0);
  }
}

.shadow-overflow-horizontal {
  position: relative;

  &::after,
  &::before {
    width: $spacer;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    position: absolute;
    z-index: 4;
    content: '';
    background: linear-gradient(to right, rgba($white, 1) 20%, rgba($white, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$white', endColorstr='$white', GradientType=0);
  }

  &::after {
    right: 0;
    left: auto;

    background: linear-gradient(to right, rgba($white, 0) 0%, rgba($white, 1) 80%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$white', endColorstr='$white', GradientType=0);
  }
}
