::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.25);
  border: 0px none #e0e0e0;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.25);
}
::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-track {
  background: rgba(127, 127, 127, 0.25);
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-track:hover {
  background: rgba(127, 127, 127, 0.25);
}
::-webkit-scrollbar-track:active {
  background: rgba(127, 127, 127, 0.25);
}
::-webkit-scrollbar-corner {
  background: transparent;
}

.thin-horizontal-scrollbar::-webkit-scrollbar {
  width: 0px;
  height: 3px;
}
