button.map-icon-button {
  margin-bottom: 10px;
  background-color: #fff;
  padding: 8px;
  color: #666;
  margin-right: 8px !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  margin-top: 8px;

  &:hover {
    background-color: #eee;
  }
}